/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    img: "img",
    strong: "strong",
    br: "br"
  }, _provideComponents(), props.components), {TableOfContents, MapInfo, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "better-hearing-begins-with-a-hearing-test",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#better-hearing-begins-with-a-hearing-test",
    "aria-label": "better hearing begins with a hearing test permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Better hearing begins with a hearing test"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The signs of ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "hearing loss"), " are usually subtle and develop over time. If people around you constantly complain that your TV is too loud, or if you have a hard time following conversations in a noisy environment, especially in group discussions, a ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), " is absolutely necessary. To find out if you have a hearing loss, only a licensed hearing care professional – ", React.createElement(_components.a, {
    href: "/audiologists/",
    className: "c-md-a"
  }, "audiologist"), " or hearing aid specialist – should evaluate your hearing. This requires an appointment with a local hearing care professional, who will use the proper techniques and equipment to provide you with accurate results. Based on your results, your hearing test will yield all the relevant data that will help the specialist recommend the right treatment for you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Would you like to find out if you have a hearing loss before visiting an audiologist’s office? Answer a few questions on the form located at the top of this page to receive a free consultation with one of our hearing aid experts. You will also receive a personalized hearing analysis with more information about the quality of your hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ultimate goal is to find the best treatment and care you need to start hearing well again."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "general-information-about-hearing-tests-audiometry",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#general-information-about-hearing-tests-audiometry",
    "aria-label": "general information about hearing tests audiometry permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "General information about hearing tests (audiometry)"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Before an audiologist can perform a hearing test, an ENT (ear, nose and throat specialist) should examine your ear canals for any sort of obstruction such as ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/earwax/",
    className: "c-md-a"
  }, "earwax (cerumen)"), ". If you arrive to the specialist’s office for a hearing evaluation with your ears clogged, you may be asked to see an ENT to have the obstruction removed. This will ensure accurate test results and a proper diagnosis. Therefore, it is important that you prepare for your hearing test by first consulting an ENT. Once your ear canals are ready for examination, your hearing care professional will perform either a subjective hearing test, an objective hearing test, or combination of the two. All of these tests fall under the umbrella of audiometry exams, which are useful in diagnosing ", React.createElement(_components.a, {
    href: "/hearing-loss/conductive/",
    className: "c-md-a"
  }, "conductive"), ", ", React.createElement(_components.a, {
    href: "/hearing-loss/sensorineural/",
    className: "c-md-a"
  }, "sensorineural"), ", or mixed hearing loss. Most of the tests will require you to sit in a quiet, soundproof room, with headphones or earphones placed over or in your ears. The headphones/earphones are connected to an audiometer, allowing the specialist to transmit sounds to your ears and record your responses on an audiogram."), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "audiometry-specialist",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#audiometry-specialist",
    "aria-label": "audiometry specialist permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Audiometry specialist"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An audiometry test measures one’s ability to hear different sounds and frequencies. The audiometry test evaluates whether hearing aids will improve your hearing and what frequencies you need to amplify. A hearing care professional, called an audiologist, conducts the exam. An audiologist may see patients in a stand-alone office or might share a practice with an Ear-Nose-Throat doctor or with a primary care physician. An audiologist has a master’s degree or PhD in audiology as well as an audiometry certification from either the American Board of Audiology or the American Speech-Language Hearing Association. At hear.com, our hearing experts are experienced and knowledgeable. They will conduct thorough tests to get a full picture of your hearing abilities and hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "before-the-audiometry-test",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#before-the-audiometry-test",
    "aria-label": "before the audiometry test permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Before the audiometry test"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are no physical preparations you must take before the audiometric screening. However, you can mentally prepare for the exam by asking the hearing consultant at hear.com lots of questions. Ask about the types of hearing aid styles, features and brands. Learn more about the technological advances in hearing aids. Read information online and look at pictures showing the various styles and colors of hearing aids. Read our ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids overview"), " page for more information about hearing aids from the top manufacturers. For information about hearing aid costs, read our ", React.createElement(_components.a, {
    href: "/hearing-aids/prices/",
    className: "c-md-a"
  }, "Hearing Aids Prices"), " page."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Lastly, write down all your questions for the audiologist before the appointment. People usually have many questions but forget to ask the audiologist. Writing down your questions ahead of time will ensure you get the information you need to maintain optimal hearing health."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "subjective-hearing-tests",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#subjective-hearing-tests",
    "aria-label": "subjective hearing tests permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Subjective hearing tests"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A subjective hearing test requires your cooperation whereas the objective hearing test does not. During a subjective hearing test, the specialist will play certain noises, sounds, or words and ask you to make a gesture in response to these sounds. This will allow the specialist to assess the severity of your hearing loss and recommend an appropriate treatment. Subjective hearing tests include pure-tone audiometry, speech audiometry, and reflex audiometry."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "pure-tone-audiometry",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pure-tone-audiometry",
    "aria-label": "pure tone audiometry permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Pure-tone audiometry"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A pure-tone audiometry requires you to sit in a quiet room with headphones/earphones on. The specialist will then play a variety of “pure” tones at different volumes, and you will be asked to give a signal (i.e. raise a finger, press a button) once you hear each tone. Your answers will indicate the tones you are having trouble hearing and will be recorded on your audiogram. Your threshold for a certain tone is determined by the softest level you are able to hear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The results of your exam will show how well your outer and middle ear structures can process sounds. This is also referred to as an air-conduction test. When this air-conduction test shows a hearing loss, the specialist may perform a bone-conduction test to differentiate between conductive hearing loss and sensorineural hearing loss. The bone-conduction test is similar to the air-conduction test in the sense that the same process is utilized. Instead of headphones, a device comfortably placed behind your ear is used to transmit the tones that will pass through your skull and inner ear, bypassing your outer and middle ear. You will then be asked to respond to the tones in the same way as the air-conduction test. This method may provide a more accurate assessment of your inner ear’s hearing capacity without any interference from your outer or middle ear."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "speech-audiometry",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#speech-audiometry",
    "aria-label": "speech audiometry permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Speech audiometry"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Speech is another tool that can help determine how well you hear. A hearing test that utilizes speech requires you to repeat two-syllable words while a tone gradually decreases to lower levels. The results of this test will indicate the level you are able to hear and understand certain sounds. A hearing test that utilizes speech may also include a word-recognition exam, where the specialist will ask you to repeat certain words to determine how well you are able to hear and understand speech. Comprehension is also examined by listening and repeating one-syllable words that are played at a comfortable listening level."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "objective-hearing-tests",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#objective-hearing-tests",
    "aria-label": "objective hearing tests permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Objective hearing tests"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An objective hearing test is useful in identifying damage to the inner ear and assessing your quality of hearing. Objective hearing tests do not require your cooperation and are typically performed on newborns and infants. Objective hearing tests include otoacoustic emission (OAE), acoustic brainstem response (ABR), acoustic reflex test, and tympanometry."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "otoacoustic-emission-oae-test",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#otoacoustic-emission-oae-test",
    "aria-label": "otoacoustic emission oae test permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Otoacoustic emission (OAE) test"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A way to identify inner ear (cochlea) damage leading to a hearing impairment is to perform an otoacoustic emision test. During this hearing evaluation, a doctor inserts a probe that emits sounds into your ear canal. These sounds stimulate the inner ear, causing the outer hair cells to vibrate. The doctor will use the probe to measure the inner ear’s ability to produce the soft sounds created by the vibration (emissions). If the inner ear does not produce emissions, a hearing impairment may be present."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "auditory-brainstem-response-abr",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#auditory-brainstem-response-abr",
    "aria-label": "auditory brainstem response abr permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Auditory brainstem response (ABR)"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An audiologist may perform this test to gain more information about your hearing sensitivity. The audiologist places a few electrodes on your head, which will measure how the hearing nerves respond to the sounds made through earphones. The information travels from the electrodes to a computer that will record the results. The audiologist will review the data and look for the softest intensity in a specific frequency range at which your hearing nerves respond."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "acoustic-reflex-test",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#acoustic-reflex-test",
    "aria-label": "acoustic reflex test permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Acoustic reflex test"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An acoustic reflex test locates the possible location of a hearing impairment. This hearing test uses sounds to determine if an acoustic reflex is working properly. Depending on the loudness of the sound and your acoustic reflex (or lack of acoustic reflex), the audiologist will be able to assess the severity of your hearing loss."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "tympanometry",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tympanometry",
    "aria-label": "tympanometry permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tympanometry"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A tympanometry allows hearing specialists to diagnose problems in the middle ear and any disorders causing hearing loss. During a tympanometry, you will hear loud tones from a probe inserted in your ear canal. This probe will measure your hearing ability, as well the movements of your eardrum as the air pressure in your ear changes during the exam. A tympanogram will record your results, and the audiologist will use this information to properly diagnose any complications with your hearing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "audiogram",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#audiogram",
    "aria-label": "audiogram permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Audiogram"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The results of your hearing tests are typically recorded on an audiogram. Therefore, it is important you understand how to read your audiogram. The purpose of the audiogram is to visually demonstrate the sounds you can hear normally and the sounds you are missing. The top of the chart indicates normal hearing; the further down the chart you go, the more challenging it may be for you to hear and understand certain sounds. For example, consonant sounds – t, s, f, th, k, sh, ch – are higher pitched and spoken more softly than vowels. If you are having trouble hearing these sounds, your test results will yield this information on the chart. Missing sounds in the higher ranges means, you can “hear” them but can’t really understand them. Some specialists will use the left side of the chart to indicate the percentage of your hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/audiogram-3-eng.jpg",
    alt: "Audiogram",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-should-you-do-about-your-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-should-you-do-about-your-hearing-loss",
    "aria-label": "what should you do about your hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What should you do about your hearing loss?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This might be the question on your mind when diagnostic tests show beyond any reasonable doubt that you have a hearing loss. The good news is, our hearing experts and ", React.createElement(_components.a, {
    href: "/about-us/network/",
    className: "c-md-a"
  }, "network of Partner Providers"), " will guide you through the process of finding the best hearing aids. So, here’s what you should do:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "1. Take action!"), React.createElement(_components.br), "\n", "Don’t ignore your hearing loss. In fact, the longer you wait, the harder it will be to treat with hearing aids. This is because hearing loss is progressive and can get worse if ignored."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "2. Check your hearing"), React.createElement(_components.br), "\n", "You can get a general idea of the quality of your hearing by filling out our online form for a risk-free hearing aid trial. Simply answer the questions and enter your email address to receive a personalized hearing analysis. You will receive a PDF file with more information about your hearing and possible solutions."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "3. Speak to an expert"), React.createElement(_components.br), "\n", "Make sure you speak to a knowledgeable expert about your current hearing situation and what your next steps should be. At hear.com, our consultations are risk-free and without obligations."), "\n", React.createElement(LandingPageCta, {
    copy: "TRY THE LATEST HEARING AIDS",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
